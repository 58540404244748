body {
  overflow: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rSelect {
  width: 100%;
  text-transform: capitalize;
}

#navbar * {
  scrollbar-width: none;
}

#navbar *::-webkit-scrollbar {
  display: none;
}

#reactTableContainer table thead,
#reactTableContainer table tbody {
  display: flex;
}

body .rSelect [class$="-MenuPortal"],
body .rSelect [class$="-control"]+div {
  left: auto !important;
}

body .address-autocomplete .rSelect input {
  opacity: 1 !important;
}

body .address-autocomplete .rSelect [class$="-singleValue"] {
  opacity: 0 !important;
}

input,
textarea {
  font-size: 12px !important;
}

.chakra-tabs__tab {
  font-size: 14px !important;
}